import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/select',
    name: 'select',
    component: () => import('../views/Company.vue')
  },
  {
    path: '/',
    name: 'myjob',
    component: () => import('../views/MyJob.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/Account.vue')
  },
  {
    path: '/transfers-point',
    name: 'transfers-point',
    component: () => import('../views/TransferPoints.vue')
  },
  {
    path: '/my-cv',
    name: 'my-cv',
    component: () => import('../views/MyCV.vue'),
  },
  {
    path: '/my-candidate',
    name: 'my-candidate',
    component: () => import('../views/MyCandidate.vue'),
  },
  {
    path: '/myorder',
    name: 'myorder',
    component: () => import('../views/MyOrder.vue')
  },
  {
    path: '/place-order',
    name: 'placeorder',
    component: () => import('../views/PlaceOrder.vue')
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('../views/Activity.vue')
  },
  {
    path: '/pricing',
    component: () => import('../views/Pricing.vue'),
    children: [
      {
        path: 'calculator',
        name: 'price_calculator',
        component: () => import('../components/pricing/calculator.vue')
      },
      {
        path: 'package',
        name: 'price_package',
        component: () => import('../components/pricing/package.vue')
      },
    ]
  },
  {
    path: '/my-company',
    name: 'my-company',
    component: () => import('../views/MyCompany.vue'),
    children: [
      {
        path: 'info',
        name: 'info',
        component: () => import('../components/mycompany/info.vue')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('../components/mycompany/users.vue')
      },
    ]
  },
  {
    path: '/postjob',
    name: 'price',
    component: () => import('../views/PostJob.vue'),
    children: [
      {
        path: 'description',
        name: 'description',
        component: () => import('../components/postjob/description.vue')
      },
      {
        path: 'description/:id',
        name: 'updatedescription',
        component: () => import('../components/postjob/description.vue')
      },
      {
        path: 'email',
        name: 'email',
        component: () => import('../components/postjob/email.vue')
      },
      {
        path: 'email/:id',
        name: 'updateemail',
        component: () => import('../components/postjob/email.vue')
      },
      {
        path: 'application',
        name: 'application',
        component: () => import('../components/postjob/application.vue')
      },
      {
        path: 'application/:id',
        name: 'updateapplication',
        component: () => import('../components/postjob/application.vue')
      },
      {
        path: 'upgrade',
        name: 'upgrade',
        component: () => import('../components/postjob/upgrade.vue')
      },
      {
        path: 'upgrade/:id',
        name: 'updateupgrade',
        component: () => import('../components/postjob/upgrade.vue')
      },
      {
        path: 'summary',
        name: 'summary',
        component: () => import('../components/postjob/summary.vue')
      },
      {
        path: 'summary/:id',
        name: 'updatesummary',
        component: () => import('../components/postjob/summary.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
