import apolloClient from "@/services/apollo-client";
import apolloClient_Company from "@/services/apollo-client-company";
import store from '../store'
import {
    GET_COMPANY,
    GET_COMPANY_TOKEN,
    GET_COMPANY_POFILE
} from "@/graphql/Company";
import {
    GET_USER_FORM
} from "@/graphql/User";
export const getCompanyApi = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apolloClient.query({
                query: GET_COMPANY,
                options: {
                    context: {
                        headers: { Authorization: store.getters.getToken || '' }
                    }
                }
            })
            resolve(res.data.selectEMP)
        } catch (e) {
            reject(e)
        }
    })
}

export const getUserApi = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apolloClient.query({
                query: GET_USER_FORM,
            })
            resolve(res.data.userInfo)
        } catch (e) {
            reject(e)
        }
    })
}

export const getCompanyTokenApi = (empId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apolloClient.query({
                query: GET_COMPANY_TOKEN,
                variables: {
                    empId
                },
            })
            resolve(res.data.empToken)
        } catch (e) {
            reject(e)
        }
    })
}
export const getCompanyPofile = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apolloClient_Company.query({
                query: GET_COMPANY_POFILE
            })
            resolve(res.data.profile)
        } catch (e) {
            reject(e)
        }
    })
}