import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
// import Vue from 'vue'
// import VueApollo from 'vue-apollo'
import { setContext } from 'apollo-link-context'
//import {baseURL} from "@/config/variables";
import { onError } from "apollo-link-error"
import store from '../store'
//const baseURL = process.env.VUE_APP_HTTPS_ADMIN
import { baseURL, logoutURL } from './variables'

const authLink = setContext(async (_, { headers }) => {
    const token = store.getters.getToken || ''
    //  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTY3YjM3MThjNTJkOTliOGM0Njk1NmUiLCJpYXQiOjE2NzIzNzE5MjYsImV4cCI6MTY3MjQ1ODMyNn0.xr_zbX7nB_JaV-rdItz1dQtQ5X4RhMJErrpF6OpbRqA'

    const form = {
        headers: {
            ...headers,
            Authorization: token || null
        },
    }
    if (!token) delete form.headers.Authorization
    return form
})

const errorLink = onError(({ graphQLErrors }) => {


    if (graphQLErrors)
        graphQLErrors.forEach((err) => {

            if (err.extensions.code === 'UNAUTHENTICATED') {
                // // alert('Logout user')
                // localStorage.clear()
                // window.location.href = logoutURL
            }
        })
    // if (networkError) console.log([Network error]: ${networkError})
})

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    }
}


const httpLink = createHttpLink({
    uri: `${baseURL}/graphql`,
})


const link = errorLink.concat(authLink.concat(httpLink))

const cache = new InMemoryCache()

const apolloClientAuth = new ApolloClient({
    link,
    cache,
    defaultOptions
})


export default apolloClientAuth
