<template>
  <div id="app">
    <LoadingScreen v-if="isLoading"></LoadingScreen>
    <navbar />
    <transition name="slidedown">
      <router-view />
    </transition>
    <SelectCompanyModal />
  </div>
</template>

<script>
import navbar from "./components/navbar";
import SelectCompanyModal from "@/views/Company.vue";
import LoadingScreen from "@/components/Confirm/LoadingScreen.vue";

export default {
  components: {
    navbar,
    SelectCompanyModal,
    LoadingScreen,
  },
  data: () => ({
    isLoading: true,
  }),
  created() {
    if (this.$route.query.token) {
      this.$store.commit("clearToken");
      setTimeout(() => {
        this.$store.commit("setToken", this.$route.query.token);
        this.$router.push({ query: {} });
      }, 500);
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
};
</script>
<style lang="scss">
body,
html {
  font-family: var(--font);
  color: var(--text-color);
  background-color: var(--bg-color);
}

.slidedown-enter-active {
  transition: all ease-in-out 0.5s;
}

.slidedown-leave-active {
  transition: all ease-in-out 0.1s;
}

.slidedown-enter {
  opacity: 0;
  transform: translateY(-30px);
}

.slidedown-leave-to {
  opacity: 0;
}

.slideup-enter-active {
  transition: all ease-in-out 0.5s;
}

.slideup-leave-active {
  transition: all ease-in-out 0.1s;
}

.slideup-enter {
  opacity: 0;
  transform: translateY(30px);
}

.slideup-leave-to {
  opacity: 0;
}
</style>
