import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: false,

    // Dev
    // accessToken:
    //   localStorage.getItem("accessToken") ||
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTY3YjM3MThjNTJkOTliOGM0Njk1NmUiLCJpYXQiOjE2NjE5OTQzMjcsImV4cCI6MTc1NjYwMjMyN30.9Iokm6zNDliQV7NfgmyLpIVG2k_IU6mt5lY0TE8sf6M",
    // accessTokenCompany:
    //   localStorage.getItem("accessTokenCompany") ||
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTY3YjM3MThjNTJkOTliOGM0Njk1NmUiLCJjb21wYW55SWQiOiI2MTY3YjM3MThjNTJkOTliOGM0Njk1NzAiLCJpYXQiOjE2NjE5OTQzMzEsImV4cCI6MTY5MzUzMDMzMX0.dOvUOVW_8dE3LjTMfY6J6CRflqtR9H5k-JfyThYumLU",

    // Production
    accessToken: localStorage.getItem("accessToken") || null,
    accessTokenCompany: localStorage.getItem("accessTokenCompany") || null,
    getProfile: localStorage.getItem("getProfile") || null,
    company: localStorage.getItem("company") || null,
    isMulti: false,
  },
  mutations: {
    setIsMulti(state) {
      state.isMulti = true;
    },
    clearToken(state) {
      state.accessToken = null;
      state.accessTokenCompany = null;
      state.getProfile = null;
      state.company = null;
      localStorage.clear();
    },
    setCompany(state, company) {
      const jsonObject = JSON.stringify(company);
      state.company = company;
      localStorage.setItem("company", jsonObject);
    },
    setProfile(state, getProfile) {
      state.getProfile = getProfile;
      localStorage.setItem("getProfile", getProfile);
    },
    setToken(state, accessToken) {
      state.accessToken = accessToken;
      localStorage.setItem("accessToken", accessToken);
    },
    setTokenCompany(state, accessTokenCompany) {
      state.accessTokenCompany = accessTokenCompany;
      localStorage.setItem("accessTokenCompany", accessTokenCompany);
    },
    setLogout(state) {
      state.accessToken = null;
    },
    setLogoutCompany(state) {
      state.accessTokenCompany = null;
    },
  },
  actions: {},
  getters: {
    getIsMulti(state) {
      return state.isMulti;
    },
    getCompany: (state) => {
      const JsonObject = JSON.parse(JSON.stringify(state.company));
      return JsonObject;
    },
    getProfile: (state) => {
      return state.getProfile;
    },
    isAuth: (state) => {
      return state.accessToken !== null;
    },
    Token: (state) => {
      return state.accessToken;
    },
    TokenCompany: (state) => {
      return state.accessTokenCompany;
    },

    getToken: (state) => {
      return state.accessToken;
    },
    getCompanyToken: (state) => {
      return state.accessTokenCompany;
    },
  },
});
