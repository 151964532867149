import gql from 'graphql-tag'

export const OFF_USER = gql`
mutation(
  $id:ID
){
  offEMPUser(id:$id){
     _id
    fName
    email
     image
    off
  }
}

`
export const DELETE_USER = gql`
mutation(  
    $id:ID!
    ){
      delete_EMP_User(id:$id)
    }
`
export const ADD_USER =  gql`
mutation(
    $image:String
    $name: String
      $email: String
      $password: String
      $role: String
    ){
      addUser(info:{
      image:$image
      name:$name 
        email: $email
        password: $password
        role: $role
       
      })
    }
`
export const UPDATE_USER = gql`
mutation(
    $_id:ID!
    $image:String
    $name: String
    $email: String
    $role:String
  ){updateUseremp(info:{
      _id:$_id
       name:$name 
      email: $email
      role:$role
      image:$image
    })
  }
`

export const GET_USER = gql`
query(
  $id:ID
){
  editempUser(id:$id){
    _id
    fName
    email
    image
    role
    off
  }
}

`

export const GET_USER_FORM = gql`
query{
  userInfo{
   _id
   fName
   email
   image
   role
   isRole
 }
 }
`

export const RESET_PASSWORD = gql `
mutation(
    $currentPassword: String
      $newPassword: String
      $confirmPassword: String
  ){
    changePW(info:{
      currentPassword:$currentPassword
      newPassword:$newPassword
      confirmPassword:$confirmPassword
    })
  }
`

export const IMAGE = gql`
mutation(
  $_id:ID
  $image: String
  
){
  updateImage(info:{_id:$_id, image:$image})
}
`
export const USER_CHANG = gql`
mutation(
  $userId: ID!
  
){
  cancelUser(info:{userId:$userId})
}
`