import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import router from '@/router/index'
import { onError } from "apollo-link-error"
import store from '../store'
import { baseURL, logoutURL } from './variables'

const authCompanyLink = setContext(async (_, { headers }) => {
    const token = store.getters.getCompanyToken || ''
    // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTY3YjM3MThjNTJkOTliOGM0Njk1NmUiLCJjb21wYW55SWQiOiI2MTY3YjM3MThjNTJkOTliOGM0Njk1NzAiLCJpYXQiOjE2NzIzNzE5NjcsImV4cCI6MTY3MjQ1ODM2N30.CgrEOK20wcRA2wc8Vpn3lVylf_VljEfPwBfz4R5Ek5E'
    const form = {
        headers: {
            ...headers,
            Authorization: token || null
        },

    }
    if (!token) delete form.headers.Authorization

    return form
})

const errorLink = onError(({ graphQLErrors }) => {

    if (graphQLErrors)
        graphQLErrors.forEach((err) => {
            if (err.extensions.code === 'UNAUTHENTICATED') {
                router.push({ path: "/" });
                localStorage.clear()
                window.location.href = logoutURL
            }
        })
    // if (networkError) console.log([Network error]: ${networkError})
})

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    }
}

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: `${baseURL}/graphql`,
})

const companyLink = errorLink.concat(authCompanyLink.concat(httpLink))

const cache2 = new InMemoryCache()

const apolloClientCompanyZ = new ApolloClient({
    link: companyLink,
    cache: cache2,
    defaultOptions
})

export default apolloClientCompanyZ

