import gql from 'graphql-tag'

export const GET_USERLIST = gql`
query{
  staffList{
    _id
    fName
    email
    image
    role
    off
  }
}
`

export const GET_COMPANY = gql`
query{
    selectEMP{
      empId
      companyName
      main
      point
      cvPoints
    }
  }
`

export const GET_COMPANY_TOKEN = gql`
query(
  $empId:ID
){
  empToken(empId:$empId)
}
`
export const STEING_EMAIL = gql`
mutation(
  $nortificationEmails: [String]
 $appliedEmails: [String]
  $notifyExpire:[String]
){
  settingEmail(info:{
    nortificationEmails:$nortificationEmails            
    appliedEmails:$appliedEmails 
    notifyExpire:$notifyExpire
  })
}
`
export const UPDATE_COMPANY = gql`
mutation(
  $logo:String
  $companyName:String!
  $aboutCompany:String!
  $industryId:[ID]!
  $companySizeId:ID!
  $website:String!
   $facebook: String!
  $districtId:ID!
   $village: String!
    $country: String!
    $phone: String! 
 
)
{
  updateEmployer 
  (
          info: {
                logo:$logo
                companyName: $companyName
                aboutCompany: $aboutCompany
                industryId: $industryId
                companySizeId: $companySizeId
                website: $website
                facebook: $facebook
                 districtId:$districtId
                village: $village
                country: $country
                phone: $phone
          } 
  ){
      _id
    logo
    companyName
    aboutCompany
    industryId{
      _id
      name
    }
    companySizeId{
      _id
      name
    }
    website
    facebook
    nortificationEmails
    appliedEmails
    village
    districtId{
      _id
      name
    }
    country
    phone
    status  
    points{
      _id
      point
    }
  }
 
}
`


export const GET_COMPANY_POFILE = gql`
query{
  profile{
    cvPoints{
			_id
			point
		}

    main
    _id
    logo
    companyName
    aboutCompany
    industryId{
      _id
      name
    }
    companySizeId{
      _id
      name
    }
    website
    facebook
    nortificationEmails
    appliedEmails
    notifyExpire
    village
    districtId{
      _id
      name
     provinceId{
      _id
      name
    }
    }
    points{
      _id
      point
    }
    country
    phone
    status  
    points{
      _id
      point
    }
}
}

`