<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <Select v-if="modalClick" @CloseModal="modalClick = false" />
    <div class="container">
      <div class="navbar-brand">
        <router-link
          :to="{
            name: 'myjob',
            query: { type: 'online', page: 1, perPage: 10, search: '' },
          }"
          class="navbar-item"
        >
          <div class="logo">
            <img :src="$urlImageShow + company.logo" />
          </div>
        </router-link>
        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <router-link
            :to="{
              name: 'myjob',
              query: { type: 'online', page: 1, perPage: 10, search: '' },
            }"
            class="navbar-item"
            >My Jobs</router-link
          >
          <router-link to="/my-company/?type=info" class="navbar-item"
            >My Company</router-link
          >
          <!-- MY CV -->
          <!-- <router-link to="/my-cv" class="navbar-item"
            >My CV -->
          <!-- <router-link
            :to="{ name: 'my-cv', query: { page: 1, perPage: 10 } }"
            class="navbar-item"
            >My CV
            <span class="beta">Beta</span>
          </router-link> -->
          <!-- candidate -->
          <!-- <router-link to="/my-candidate" class="navbar-item"
            >My Candiate</router-link
          > -->

          <!-- <router-link to="/myorder" class="navbar-item">My Order</router-link> -->
          <!-- <router-link to="/pricing/calculator" class="navbar-item">Pricing</router-link> -->
          <router-link to="/activity" class="navbar-item">Activity</router-link>
          <!-- <router-link to="/showpoint?type=transfer" class="navbar-item">Point historied</router-link> -->
          <router-link
            v-if="check === true && checkget.length > 1"
            to="/transfers-point"
            class="navbar-item"
          >
            Transfer
          </router-link>
          <router-link
            to="/postjob/description/?post=postjob"
            class="navbar-item"
            ><button class="button info">Post Job</button></router-link
          >
        </div>
        <!-- Navbar start -->

        <div class="navbar-end">
          <div class="navbar-item">
            CV Points:
            <span v-if="company.cvPoints" class="points">{{
              company.cvPoints.point ? company.cvPoints.point : "0"
            }}</span>
            <span v-else class="points">0</span>
          </div>
          <div class="navbar-item">
            Post Points:
            <span class="points" v-if="company.points">{{
              company.points.point
            }}</span>
          </div>
          <div class="navbar-item dropdown" :class="{ active: dropdown }">
            <div
              v-if="!imageData"
              class="user-image"
              @click="dropdown = !dropdown"
            ></div>
            <div
              @click="dropdown = !dropdown"
              v-else
              class="user-image"
              :style="{ backgroundImage: `url(${imageData})` }"
            ></div>
            <ul>
              <li>
                <router-link to="/account">
                  <i class="fas fa-user-alt"></i>Account Preferences
                </router-link>
              </li>
              <!-- <li v-if="user.isRole === true">
              <router-link to="/points">
                <i class="fas fa-coins"></i>Points
              </router-link></li> -->
              <li @click="OpenModal()" v-if="$store.getters.getIsMulti">
                <i class="fal fa-file-alt"></i>Select Company
              </li>
              <li @click="logout()">
                <i class="fas fa-sign-out-alt"></i>Sing out
              </li>
            </ul>
          </div>
        </div>
        <!-- Navbar-end -->
      </div>

      <!-- insufficient Point -->
      <div class="modal-backgroud"></div>
      <!-- <noPoints v-if="dropdown"/> -->
    </div>
  </nav>
</template>

<script>
import { GET_COMPANY_POFILE } from "../graphql/Company";
import Select from "../views/Company.vue";
import apolloClientCompany from "@/services/apollo-client-company";
import { getCompanyApi, getUserApi } from "@/apis/company-api";
export default {
  components: {
    Select,
  },
  data: () => ({
    check: false,
    dropdown: false,
    modalClick: false,
    company: [],
    user: [],
    imageData: "",
    checkget: [],
  }),
  methods: {
    async getCompany() {
      if (this.$store.getters.getToken) {
        this.checkget = await getCompanyApi();
      }
    },
    logout() {
      localStorage.clear();
      if (process.env.NODE_ENV === "production")
        window.location.href = `${process.env.VUE_APP_HTTPS_ClIENT2}/?logout=1`;
      else
        window.location.href = `${process.env.VUE_APP_HTTPS_ClIENT2}/?logout=1`;
    },
    OpenModal() {
      this.$router.push({ path: "/", query: { modalStatus: "Show" } });
    },
    async getUser() {
      const userInfo = await getUserApi();
      this.user = userInfo;
      this.imageData = this.user.image;
      this.check = this.user.isRole;
    },
    async getCompanyPofile() {
      const res = await apolloClientCompany.query({
        query: GET_COMPANY_POFILE,
      });
      this.company = res.data.profile;
      if (res.data.profile.cvPoints === null) {
        const cvPoints = "0";
        this.$store.commit("setProfile", cvPoints);
      } else {
        this.$store.commit("setProfile", res.data.profile.cvPoints.point);
      }
    },
  },
  watch: {
    "$store.getters.getCompany": {
      handler(value) {
        if (value) {
          this.checkget = this.$store.getters.getCompany;
          this.getUser();
          // this.getCompany();

          this.getCompanyPofile();
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid var(--border-color);
  height: 4rem;
  background-color: var(--primary-color);
  position: sticky;
  top: 0;
  z-index: 8;

  .navbar-brand {
    background-color: #fff;
    margin-right: 10px;
  }

  .navbar-item {
    color: var(--grey-color);
    position: relative;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }

    .beta {
      position: relative;
      background-color: var(--alert-color);
      padding: 1px 5px;
      font-size: var(--xsm-font);
      font-weight: normal !important;
      color: #fff;
      border-radius: 3px;
      right: 0;
      margin-left: 5px;
      top: -8px;
    }

    .points {
      color: var(--info-color);
      margin-left: 10px;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

.router-link-active {
  text-shadow: 0 0 1px #fff !important;
  color: #fff !important;
  background-color: transparent !important;
}

.logo {
  background-color: #fff;
  height: 48px;
  min-width: 48px;
  max-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  img {
    display: block;
    max-height: 90%;
    max-width: 90%;
  }
}

.user-image {
  background-image: url("../../public/images/user-avatar/user-avatar-02.png");
  cursor: pointer;
  overflow: hidden;
  width: 40px;
  height: 40px;
  display: block;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 6px 2px rgba(#000, 0.3);
}

.dropdown {
  position: relative;
  border-left: 1px solid rgba(var(--dark-grey-color), 0.3);

  &.active {
    position: relative;
    z-index: 9;
    ul {
      display: block;

      li {
        a.router-link-active {
          color: var(--primary-color) !important;
        }
      }
    }
  }

  ul {
    display: none;
    position: absolute;
    top: 60px;
    border-radius: 5px;
    right: 0;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    animation: slide-up 0.2s;

    li {
      font-size: 16px;
      padding: 15px 30px;
      cursor: pointer;
      white-space: nowrap;
      color: var(--text-color);

      i {
        margin-right: 10px;
        color: var(--primary-color);
      }

      a {
        color: var(--text-color);
      }

      &:hover {
        background-color: var(--grey-color);
      }

      &:last-child {
        border-top: 1px solid var(--border-color);
      }
    }
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
