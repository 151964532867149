<template>
  <div
    v-show="
      $route.query.modalStatus === 'Show' || !$store.getters.getCompanyToken
    "
    class="modal is-active"
  >
    <div class="modal-background"></div>
    <div class="modal-content slide-down">
      <div class="box">
        <div class="box-header">
          <h3>Select Company</h3>
        </div>
        <table class="table" id="my-table">
          <thead>
            <tr>
              <th>First Name</th>
              <th class="is-xs">Option</th>
            </tr>
          </thead>
          <tbody v-for="(i, index) in company" :key="index">
            <tr :class="{ true: i.main }">
              <td>
                {{ i.companyName }}
                <h2 v-if="i.main == true">(primary)</h2>
              </td>
              <td @click="getSelectedCompany(i.empId, i)" class="is-right">
                <div class="icon-group">
                  <span><i class="fas fa-expand"></i></span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <button @click="CloseModal()" class="modal-close is-large"  aria-label="close"></button> -->
  </div>
</template>
<script>
import { getCompanyApi, getCompanyTokenApi } from "@/apis/company-api";
import { logoutURL } from "@/services/variables";
export default {
  name: "SelectCompanyModal",
  data: () => ({
    company: [],
  }),

  watch: {
    "$store.getters.getCompanyToken": {
      handler(val) {
        if (val && this.company.length !== 1) {
          this.$nextTick(() => {
            this.$router.push({
              path: "/",
              query: {
                companyStatus: "Logged In",
                type: "online",
                page: 1,
                perPage: 10,
                search: "",
              },
            });
            this.$nextTick(() => {
              this.$router.push({
                query: { type: "online", page: 1, perPage: 10, search: "" },
              });
            });
          });
        }
      },
    },
  },
  methods: {
    async getCompanies() {
      try {
        const data = await getCompanyApi();
        this.company = data;
        if (data.length === 1) {
          const empId = this.company[0].empId;
          this.getSelectedCompany(empId, this.company[0]);
        } else {
          this.$store.commit("setIsMulti");
        }
      } catch (e) {
        this.logOut();
      }
    },
    async getSelectedCompany(empId, company) {
      try {
        const companyToken = await getCompanyTokenApi(empId);
        if (!companyToken) return this.logOut();
        this.$store.commit("setCompany", company);
        this.$store.commit("setTokenCompany", companyToken);
        if (this.company.length !== 1) {
          this.$router.push({
            ...this.$route,
            query: {
              ...this.$route.query,
              modalStatus: "Not show",
            },
          });
        }
      } catch (e) {
        this.logOut();
      }
    },
    logOut() {
      localStorage.clear();
      window.location.href = logoutURL;
    },
  },
  created() {
    setTimeout(() => {
      this.getCompanies();
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.true * {
  font-weight: bold !important;
  background-color: rgba(var(--grey-color), 0.15) !important;
}

.modal {
  z-index: 999;

  .modal-background {
    background-color: rgba(var(--dark-grey-color), 0.3);
    backdrop-filter: blur(5px);
  }

  .modal-content {
    z-index: 999;
    max-width: 450px;
    overflow-y: auto;

    .box {
      padding: 30px;
      //border-radius: $radius;
      animation: fade 0.3s;
      background-color: #fff;
      box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);

      @keyframes fade {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      .box-header {
        h3 {
          font-size: var(--xlg-font);
          font-weight: 700;
          text-transform: uppercase;
          color: var(--primary-color);
        }
      }

      .form {
        margin: 20px 0 30px;

        .field {
          margin-bottom: 20px;

          .label {
            margin-bottom: 5px;
            font-size: var(--sm-font);
          }

          a {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
            color: var(--alert-color);
            font-size: var(--sm-font);
          }
        }
      }

      p {
        width: 100%;
        justify-content: center;
        display: flex;
        text-align: center;
        font-size: var(--sm-font);

        a {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
